<template>
  <div class="Benefits">
    <!--beginning of the page -->
    <center class="begin" data-aos="zoom-out-up">
      <h1 class="container-fluid">IEEE Member Ship</h1>
    </center>
    <section class="benefits">
      <div class="container">

        <div class="header">
          <h2>Benefits</h2>
          <p>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus, ad pro quaestio laboramus. Ei ubique vivendum pro. At ius nisl accusam lorenta zanos paradigno tridexa panatarel.</p>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6" data-aos="flip-left">
            <div class="content">
              <div class="icon" >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bootstrap-fill" viewBox="0 0 16 16">
                <path d="M6.375 7.125V4.658h1.78c.973 0 1.542.457 1.542 1.237 0 .802-.604 1.23-1.764 1.23H6.375zm0 3.762h1.898c1.184 0 1.81-.48 1.81-1.377 0-.885-.65-1.348-1.886-1.348H6.375v2.725z"/>
                <path d="M4.002 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4h-8zm1.06 12V3.545h3.399c1.587 0 2.543.809 2.543 2.11 0 .884-.65 1.675-1.483 1.816v.1c1.143.117 1.904.931 1.904 2.033 0 1.488-1.084 2.396-2.888 2.396H5.062z"/>
                </svg>
              </div>
              <div>
                  <h3>Lorem Ipsum</h3>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
              </div>

            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="flip-left">
            <div class="content">
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bootstrap-fill" viewBox="0 0 16 16">
                <path d="M6.375 7.125V4.658h1.78c.973 0 1.542.457 1.542 1.237 0 .802-.604 1.23-1.764 1.23H6.375zm0 3.762h1.898c1.184 0 1.81-.48 1.81-1.377 0-.885-.65-1.348-1.886-1.348H6.375v2.725z"/>
                <path d="M4.002 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4h-8zm1.06 12V3.545h3.399c1.587 0 2.543.809 2.543 2.11 0 .884-.65 1.675-1.483 1.816v.1c1.143.117 1.904.931 1.904 2.033 0 1.488-1.084 2.396-2.888 2.396H5.062z"/>
                </svg>
              </div>
              <div>
                  <h3>Lorem Ipsum</h3>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
              </div>

            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="flip-left">
            <div class="content">
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bootstrap-fill" viewBox="0 0 16 16">
                <path d="M6.375 7.125V4.658h1.78c.973 0 1.542.457 1.542 1.237 0 .802-.604 1.23-1.764 1.23H6.375zm0 3.762h1.898c1.184 0 1.81-.48 1.81-1.377 0-.885-.65-1.348-1.886-1.348H6.375v2.725z"/>
                <path d="M4.002 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4h-8zm1.06 12V3.545h3.399c1.587 0 2.543.809 2.543 2.11 0 .884-.65 1.675-1.483 1.816v.1c1.143.117 1.904.931 1.904 2.033 0 1.488-1.084 2.396-2.888 2.396H5.062z"/>
                </svg>
              </div>
              <div>
                  <h3>Lorem Ipsum</h3>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
              </div>

            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="flip-left">
            <div class="content">
              <div class="icon" >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bootstrap-fill" viewBox="0 0 16 16">
                <path d="M6.375 7.125V4.658h1.78c.973 0 1.542.457 1.542 1.237 0 .802-.604 1.23-1.764 1.23H6.375zm0 3.762h1.898c1.184 0 1.81-.48 1.81-1.377 0-.885-.65-1.348-1.886-1.348H6.375v2.725z"/>
                <path d="M4.002 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4h-8zm1.06 12V3.545h3.399c1.587 0 2.543.809 2.543 2.11 0 .884-.65 1.675-1.483 1.816v.1c1.143.117 1.904.931 1.904 2.033 0 1.488-1.084 2.396-2.888 2.396H5.062z"/>
                </svg>
              </div>
              <div>
                  <h3>Lorem Ipsum</h3>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
              </div>

            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="flip-left">
            <div class="content">
              <div class="icon" >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bootstrap-fill" viewBox="0 0 16 16">
                <path d="M6.375 7.125V4.658h1.78c.973 0 1.542.457 1.542 1.237 0 .802-.604 1.23-1.764 1.23H6.375zm0 3.762h1.898c1.184 0 1.81-.48 1.81-1.377 0-.885-.65-1.348-1.886-1.348H6.375v2.725z"/>
                <path d="M4.002 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4h-8zm1.06 12V3.545h3.399c1.587 0 2.543.809 2.543 2.11 0 .884-.65 1.675-1.483 1.816v.1c1.143.117 1.904.931 1.904 2.033 0 1.488-1.084 2.396-2.888 2.396H5.062z"/>
                </svg>
              </div>
              <div>
                  <h3>Lorem Ipsum</h3>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
              </div>

            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="flip-left">
            <div class="content">
              <div class="icon" >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bootstrap-fill" viewBox="0 0 16 16">
                <path d="M6.375 7.125V4.658h1.78c.973 0 1.542.457 1.542 1.237 0 .802-.604 1.23-1.764 1.23H6.375zm0 3.762h1.898c1.184 0 1.81-.48 1.81-1.377 0-.885-.65-1.348-1.886-1.348H6.375v2.725z"/>
                <path d="M4.002 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4h-8zm1.06 12V3.545h3.399c1.587 0 2.543.809 2.543 2.11 0 .884-.65 1.675-1.483 1.816v.1c1.143.117 1.904.931 1.904 2.033 0 1.488-1.084 2.396-2.888 2.396H5.062z"/>
                </svg>
              </div>
              <div>
                  <h3>Lorem Ipsum</h3>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
              </div>

            </div>
          </div>
        </div>

      </div>
    </section>
  </div>

</template>

<script>

export default{
  name:'Benefits',
  data(){
    return {
      url: 'https://images.pexels.com/photos/235986/pexels-photo-235986.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    }}

}
</script>

<style scoped>
.begin{
  position: relative;
  height: 500px;
  background-color:rgb(179, 204, 255);
}
 h1{
   position: absolute;
  top:250px;
}

section{
  padding-top:50px;
  background-color:rgb(219, 214, 214);
}
img{
  max-width:100%;
  height:auto;
}
.content{
  display: flex;
  margin-bottom:20px;
}

.benefits{
  background:white;
  padding-bottom: 20px;
}
.benefits .header{
  text-align: center;
  }

.benefits .header p{
    margin-bottom: 30px;
    padding-bottom: 30px;
    color:rgb(81, 72, 72);
}
  .benefits .icon{
    padding-right:20px;
    padding-top: 5px;
  }

svg{
  color:rgb(102, 153, 255);
}
.benefits .header h2{
  position: relative;
  padding-bottom:15px;
  margin-bottom:15px;
}

.benefits .header h2::before{
  content: '';
  width: 130px;
  height:2px;
  position: absolute;
  display: block;
  background:rgb(219, 214, 214);
  left:calc(50% - 65px);
  bottom: 0;
}

.benefits .header h2::after{
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height:3px;
  left:calc(50% - 22px);
  bottom: 0px;
  background:rgb(102, 153, 255);
}
h3{
  font-size:20px;
  margin-bottom: 20px;
}
.content p{
  color:rgb(135, 120, 120);
}
</style>
